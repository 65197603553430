.footer{
    bottom: 0;
    Padding-top:5px;
    background-color:#ff6a00;
}
.whole-footer{
    padding: "20px";
    position: "fixed";
    left: "0";
    bottom: "0";
    height: "60px";
    width: "100%";
}
.iconsocial{

    color:#ffffff;
    font-size:35px
}
.food1{
    Padding-top:35px;
    bottom: 0;
    
    background-color:#000000;
    text-align: center;
    align-items: center;
}

.footer-link{
    color: white;
}
.footer-nav{
    text-align: center;
    display: flex; 
    justify-content: space-around;
}
.footer1{
    padding-top: 10px;
}

.footer-nav li > a {
    color: white;
  }
.footer-nav li a:hover {
    background-color: #ff6a00;
    color: green;
}
