.food-type{
  width: 25px;
  height: 25px;
}
.food-name{
  display: flex;
  justify-content: center;
  align-content: space-between;
}

.foodlist-fname{
  color: black;
}

.fc-list-banner{
  background-color: #e40000;
  border-radius: 5px;
  margin-top: 70px;
}

.food-header-text{
  font-size: 24.19px !important;
  font-family: Montserrat;
  /* text-transform: uppercase; */
  letter-spacing: 2.9px;
  color: #ffff;
  margin-bottom: 25px !important;
} 

.fclist-row{
  margin-top: 20px;
}
/*-----*/
.foodItems-fclist{
  height: 450px;
  width: 380px;
  display: inline-block;
  outline: none;
}


.fcategory-list-food-name{
  display: flex;
  justify-content: center;
}

.fcategory-list-fname{
  color: #666;
  font-family: Helvetica;
}


@media only screen and (max-width:  768px) {

  .foodItems-fclist{
    height: 450px;
    width:380px;
    display: inline-block;
    outline: none;
  }

}


@media only screen and (max-width:  380px) {

  .foodItems-fclist{
    height: 430px;
    width:330px;
    display: inline-block;
    outline: none;
  }

}
@media only screen and (max-width:  330px) {

  .foodItems-fclist{
    height: 430px;
    width:300px;
    display: inline-block;
    outline: none;
    padding-right: 12px;
  }

}

