.signup-label {
    font-family : 'Archivo';
    font-size: 10px;
    margin-bottom: 15px;
}


/*https://stackoverflow.com/questions/32800224/is-it-possible-to-have-buttons-with-different-widths-while-using-bootstraps-btn*/

.signup-modal .modal-body {
    padding: 10px 7% 7% 7%;
}

.signup-modal .modal-body h1 {
    font-size: 20px;
    color: #474646;
    margin-bottom: 8%;
}


.signup-modal .modal-body h1 span {
    font-size: 12px;
}

.signup-modal .modal-body h1 a {
    font-size: 12px;

}


.signup-modal .btn-group {
    margin-bottom: 15px;
}

.signup-modal .btn-group-justified > .btn-group:nth-of-type(odd) {
width: .25%;
}

.signup-modal .modal-header {
    padding : 15px 15px 0px 0px;
    border-bottom : none;
}


.checkbox {
    display: inline;
}

.buffer {
    height : 4px;
    margin-bottom: 1px;
}

@media only screen and (min-width: 768px) {
    .signup-modal {
        width : 600px;
    }

    .left-padding-social {
        padding-left: 0px;

    }

    .form-bar {
        border: 1px solid pink;
        border-width: 0 15px 0px 0px;
        padding-right: 10px;
        border-image: url("border.png");
        border-image-repeat: repeat;
        border-image-slice: 0 15 0 0;
    }
}
