input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.otpinput{

width:200px;
border-radius:10px;
border:none;
outline:none;
background:#E1E6DF  ;
text-align:center;
height:30px;
font-size: 20px;
}

.otpinput::-webkit-input-placeholder {
    font-size: 10px;
    line-height: 3;
}
