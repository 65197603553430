.billing input{

    width:100%;
    height:40px;
    border-radius:10px;
    outline:none;
    background-color:rgb(202, 202, 202)    ;
    border-top:none;
    border-left:none;
    padding-left:20px;
    color:#065313;
}
.billing select{
    width:100%;
    height:40px;
    border-radius:10px;
    outline:none;
    background-color:rgb(202, 202, 202)   ;
  border:none;
    padding-left:20px;
    color:#065313;

}
h4, h3{
    font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
}
.priceWidth{
    width: 400px;
}
.priceWidthTotal{
    width: 800px;
}
.radiobutton input{

    width:50px;
    height:20px;
  
    color:green;
    background-color: green;
}