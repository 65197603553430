.restaurant-pic {
    width: 100%;
    transform: scale(1.0);
    transition: all 0.5s;
  }

.loader{
  display: flex;
  justify-content: center;
}

.top-left-closed{
  position: absolute;
  top: 10px;
  z-index: 4;
  color: rgb(238, 226, 226);
  width: 140px;
  background-color: #ff3300;
}