body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #2892d7;
}

p {
  color: grey;
  font-weight: 300;
}
.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white; }

.App-title {
  font-size: 1.5em; }

.App-intro {
  font-size: large; }

.App2 {
  text-align: center; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }


/*
.profile-pic {
  width: 100%;
  transform: scale(1.0);
  transition: all 0.5s;
}*/
/*
.btn-primary {
  background-image: none;
  border: none;
  border-radius: 0;
  background-color: #2892D7;
}*/

h3 {
  margin-bottom: 1em;
}

.home-carousal{
 background: black;
}


.underline {
 
  color: #666;
  /*border-bottom: rgb(236, 101, 11) 2px solid;*/
  font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 3.8px;
}

.home-banner-phon-time{
  background-color: #e40000;
  border-radius: 6px;
  margin-top: 1px;
  padding-top: 3px;
  padding: 12px;
  letter-spacing: 2.3px;

  
}

.home-banner-text{
  text-align: center;
  font-size: 20px !important;
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  color: #ffff;
  line-height: 5px;
}

.quant-inc-drc {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 35px;
}
.homeLineBreak{
  visibility: hidden;
}
.phone-text1{
  color: white;
  font-size: 20px;
}
.phone-text2{
  color: white;
  font-size: 20px;
}


@media only screen and (max-width:  768px) {
  .home-banner-phon-time{
    background-color: #e40000;
    border-radius: 6px;
    padding: 0px;
    letter-spacing: 0.09px;
    
  }
  .home-banner-phone-time{
    display: flex;
    flex-flow: row wrap; 
    justify-content: space-around;
  }
  .phone-text1{
    color: white;
    font-size: 17px;
  }
  .phone-text2{
    color: white;
    font-size: 17px;
  }
  
 
.home-banner-text{
  text-align: center;
  font-size: 17px !important;
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  color: #ffff;
  line-height: 5px;
}

}



.item {
    width: 100%;
  
}

.item img {
    width: 100%;
    height: 60%;
  
}



.show-grid{

  margin:0px;
}

  

  h3 {
    margin-bottom: 1em;
  }
  
  .home-carousal{
   background: black;
  }
  
 
  /*
  .pickofday-img:hover {
    border:10px solid #fff;
    box-shadow:0px 0px 5px #aaa;
  }*/
  
  .potd-head-underline {
    padding-bottom: 22px;
    color: black;
    border-bottom: rgb(236, 101, 11) 2px solid;
  }
 
 

nav.navbar, .navbar-default {
  background-color: white !important;
  box-shadow: 0px 3px 9px rgba(133, 127, 127, 0.49); 
}
.navbar-default {
  background-image: none;
  border: none;
  border-radius: 0;
  height: 61px;
}
.navbar-default .navbar-nav > li > a {
  color: rgb(51, 160, 18);
  font-size: 14px;
  padding-top: 20px;
}
.navbar-default .navbar-brand {
  color: white;
}
.navbar-default .navbar-toggle {
  border-color: rgb(52, 143, 10);
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: rgb(38, 180, 20);
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color: white;
  background-color: transparent;
}
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: rgb(13, 118, 204);
  background-color: transparent;
}
.toi-logo{
  width: 200px;
  height: 35px;
}
.toi-cart{
  width: 40px;
  height: 40px;
}
.pop-menu-category {
  vertical-align: text-bottom;
}



/*common classes*/
.foodcat-trow{
  margin-top: 50px;
}
.fcat-food-type-menu{
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
}
.num-input{
  width: 100px;
}

.food-price{
  color: black;
}

.f-img {
  width: 100%;
  border-radius: 7px;
  margin-top: 5px;
}

.col-wrapper {
  margin-bottom: 2em;
  outline: none;
  margin-left: 5px;
  margin-right: 5px;
}
.col-wrapper:hover .f-img {

  -webkit-transform: translate(0, -5px);

          transform: translate(0, -5px);
  transition: all 0.5s;
}

.col-cart{
  margin-bottom: 4em;
  outline: none;
}

.col-cart:hover .f-img {

  -webkit-transform: translate(0, -5px);

          transform: translate(0, -5px);
  transition: all 0.5s;
}

.fcategory-list-addsection{
  display: flex;
  justify-content: center;
  margin-top: 19px;
}
.fname{
    
  color: #666;
  text-transform: capitalize;
  font-size: 17px;
  font-family: 'Montserrat';
}
.fnameOldPrice{
    
  color: #666;
  text-transform: capitalize;
  font-size: 19px;
  font-family: 'Montserrat';
}
.fnamePrice{
  color: #666;
  text-transform: capitalize;
  font-size: 20px;
  margin-left: 18px;
  font-family: 'Montserrat';
}
.foodlist-type-name{
  display: flex;
  justify-content: center;
}

.fcategory-list-food-type{
  width: 18px;
  height: 18px;
  margin-top: 10px
}

.homepickday-food-name {
  display: flex;
  justify-content: center;
  position: relative;
}
.top-left {
  position: absolute;
  top: -30px;
  left: -2px;
  z-index: 2;
  color: white;
  width: 90px;
  background-color: green;
}
.top-left-tagline {
  position: absolute;
  top: -30px;
  left: -2px;
  z-index: 2;
  color: white;
  width: 90px;
  background-color: #ff6a00;
}

.homepickday-addsection{
  display: flex;
  justify-content: center;
  margin-top: 19px;
}

.pickofday-food-type{
  width: 18px;
  height: 18px;
  
  margin-top: 10px;
}

.table-wide {
  width: 500px;
}


.thead-row{
  max-width: 500px;
  
}
.btn-success{
  font-family: Montserrat;
  
  
}

.cart-model-navbar-mobile{
  visibility: hidden;
}



@media only screen and (max-width:  768px) {
  .col-home-rest-list{
    padding: 7px;
  }
  .slick-next {
   right: 0px;
   visibility: hidden;
  }
 
  .slick-prev {
    left: 0px;
    visibility: hidden;
}
  .col-wrapper  {
    margin-bottom: 2em;
    outline: none;
    margin-left: 3px;
    margin-right: 3px;
  }
  .toogle-search{
    margin-left: 20px;
    margin-top: -11px;
  }

  .toogle-box{
    margin-top: -9px;
    margin-left: 10px;
  }
  .nav-collapse-in-mobile{
    background-color: white;
    margin-top: 0px;
    padding-left: 0px;
    margin-top: -70px;
    
  }
  .cart-model-navbar {
    visibility: hidden;
  }

  .cart-model-navbar-mobile{
    margin-top: -9px;
    padding-left: 10px;
    visibility: visible;
    outline: none;
  }
 
  .login-singup-mobile-view{
    padding-left: 14px;
    margin-top: -3px;
  }
  .toi-logo{
    width: 115px;
    height: 33px;
    margin-left: -15px;
  }

  .col-wrapper:hover .f-img {

    -webkit-transform: translate(0, 0px);

            transform: translate(0, 0px);
    transition: all 0s;
  }
}

.ex4 .p1[data-count]:after{
    position: absolute;
    right: 2%;
    top: -3%;
    content: attr(data-count);
    font-size: 49%;
    padding: .3em;
    border-radius: 50%;
    line-height: 1em;
    color: white;
    background: rgba(255,0,0,.85);
    text-align: center;
    min-width: 1.5em;
  }
.p1 {
    color: rgb(236, 101, 11);
}
.ex4 {
    height: 10px;
    padding-bottom: 40px;
}
.tTotalQuant{
    height: 50px; 
    width: 50px;
    text-align: left;
    vertical-align: middle;
}
.tdTotal 
{
    height: 50px; 
    width: 50px;
    text-align: left;
    vertical-align: middle;
}
.tdTotalPrice 
{
    height: 50px; 
    width: 1900px;
    text-align: left;
    vertical-align: middle;
}
.tdTotalFoodName
{
    height: 50px; 
    width: 800px;
    text-align: left;
    vertical-align: middle;
}

.cart-empty-div{
    display: flex; 
    justify-content: center;
}
.emptyCart{
    height: 300px;
    width: 350px;
}
.addonDiv{
    display: flex;
    flex-direction: row;
    align-items: center;

}
.signup-label {
    font-family : 'Archivo';
    font-size: 10px;
    margin-bottom: 15px;
}


/*https://stackoverflow.com/questions/32800224/is-it-possible-to-have-buttons-with-different-widths-while-using-bootstraps-btn*/

.signup-modal .modal-body {
    padding: 10px 7% 7% 7%;
}

.signup-modal .modal-body h1 {
    font-size: 20px;
    color: #474646;
    margin-bottom: 8%;
}


.signup-modal .modal-body h1 span {
    font-size: 12px;
}

.signup-modal .modal-body h1 a {
    font-size: 12px;

}


.signup-modal .btn-group {
    margin-bottom: 15px;
}

.signup-modal .btn-group-justified > .btn-group:nth-of-type(odd) {
width: .25%;
}

.signup-modal .modal-header {
    padding : 15px 15px 0px 0px;
    border-bottom : none;
}


.checkbox {
    display: inline;
}

.buffer {
    height : 4px;
    margin-bottom: 1px;
}

@media only screen and (min-width: 768px) {
    .signup-modal {
        width : 600px;
    }

    .left-padding-social {
        padding-left: 0px;

    }

    .form-bar {
        border: 1px solid pink;
        border-width: 0 15px 0px 0px;
        padding-right: 10px;
        border-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAACdCAQAAADBceqJAAAABGdBTUEAAK/INwWK6QAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiChUQCwU1lyERAAAA8klEQVRYw+2UwVHDMBBFn5LcER2IDtSBQwWkBFew4xJCB56tgBLcAU4FqATnzEUUwCgHO5N4ODKBQPZfJM3T/L9ajeQK5/osS3e+Xswo881fMN/CFzW/aPbfbcutmltTzfwWfibLHuXKz1Vu+Orxahw0EIAkeZonyRrJrqCejoodEVhL0oYtCah4XgA9ngdZi6enA2l5oWLgXrYrrQkEyQC0vAIQ2Us9Zm/oJnhSpDlW7jnBhh1o4I7+iDtqjaBeW55ogMiHDBOWlp43TQxseJQERNLsGWjEkyXZKzE803v5vWyHZf+rbDM3czu33dj1t+UAlJBP/tbkc80AAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMTAtMjFUMTY6MTE6MDUrMDA6MDCdl+YtAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTEwLTIxVDE2OjExOjA1KzAwOjAw7MpekQAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAAASUVORK5CYII=);
        border-image-repeat: repeat;
        border-image-slice: 0 15 0 0;
    }
}


.nav-overlay-content {
    top: 64px;
    position: fixed; 
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    z-index: -1000;
    width: 100%;
    transition: width 10s ease-in;
    overflow-y: scroll;
    max-height: 90%;
}

.nav-hide {
    display: none
}


.nav-overlay-background {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 220;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    text-align:center;
    overflow-y: scroll;
    max-height: 90%;

}
.headings{
margin-bottom: 10px;
font-family: Montserrat;
text-transform: uppercase;
letter-spacing: 2.3px;
 
}
h4{
    color:rgb(102, 98, 98);
}
.navdrop{
    background-color:#F5F0EE ;
    padding:20px;
    border-radius:10px;
}
a{
    color:black;
}
.dispalaytogether{
    display:flex;
}

.category-list{
 margin-bottom: 10px;
 text-align: left;
}

.foodCatRest{
    font-family: 'Montserrat';
    font-weight: normal;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
}

@media only screen and (max-width:  768px) {

    .foodCatRest{
        font-family: 'Montserrat';
        font-weight: normal;
        font-style: normal;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.1px;
    }

}

@media only screen and (max-width:  320px) {

    .foodCatRest{
        font-family: 'Montserrat';
        font-weight: normal;
        font-style: normal;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1px;
    }

}



.nav-overlay-contents {
    top: 64px;
    position: fixed; 
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    z-index: -1000;
    width: 100%;
    transition: width 10s ease-in;
  
}

.nav-hide {
    display: none
}


.nav-overlay-backgrounds {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 220;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    text-align:center;
   
}
.headings{
margin-bottom: 10px;
font-family: Montserrat;
text-transform: uppercase;
letter-spacing: 2.3px;
 
}
h4{
    color:rgb(102, 98, 98);
}
.navdrop{
    background-color:#F5F0EE ;
    padding:20px;
    border-radius:10px;
}
a{
    color:black;
}
.dispalaytogether{
    display:flex;
}

.category-list{
 margin-bottom: 10px;
 text-align: left;
}

.foodCatRest{
    font-family: 'Montserrat';
    font-weight: normal;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
}

@media only screen and (max-width:  768px) {

    .foodCatRest{
        font-family: 'Montserrat';
        font-weight: normal;
        font-style: normal;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.1px;
    }

}

@media only screen and (max-width:  320px) {

    .foodCatRest{
        font-family: 'Montserrat';
        font-weight: normal;
        font-style: normal;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1px;
    }

}


body {
    font-family: Helvetica, sans-serif;
  }
  
  .react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 12px;
    border: 1px solid #f70d0d;;
    background-color: #f2f2f3;
   
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 40px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  .searchgrid{
    width:40%;
  }
  @media screen and (max-width: 900px) {
    .searchgrid {
      width:100%;
      margin-left:10px;
    }

    
  }
  .selectoption{

   
      height: 40px;
      border-top-left-radius: 20px;
     
      width: 100%;
      color:white;
      padding-left:30px;
      background-color: rgb(232, 99, 46);
      outline:none;
      border: 1px solid rgb(230, 119, 85);
 
  }
  @media screen and (max-width: 900px) {
    .selectoption{

   
      height: 40px;
      border-top-left-radius: 20px;
     
      width: 100%;
      color:white;
      padding-left:5px;
      font-size: 10px;
      background-color: rgb(232, 99, 46);
      outline:none;
      border: 1px solid rgb(230, 119, 85);
 
  }
    
  }
.category-sty {
  height: 20px;
  width: 20px;
  fill: black; }
  .category-sty:hover {
    fill: orange; }

.restaurant-pic {
    width: 100%;
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    transition: all 0.5s;
  }

.loader{
  display: flex;
  justify-content: center;
}

.top-left-closed{
  position: absolute;
  top: 10px;
  z-index: 4;
  color: rgb(238, 226, 226);
  width: 140px;
  background-color: #ff3300;
}
.header-image {
  width: 100%;
  margin-top: -2em;
  margin-bottom: 2em;
}

.about-profile-pic {
  float: left;
  margin: 0 1em 1em 0;
}

.about-root-div{
  margin-top: 70px;
  padding-bottom: 20px;
}

.about-h1{
  font-size: 24.19px !important;
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  color: #464646;
  margin-bottom: 34px !important;
}
.about-p-text{
  font-size: 19px !important;
  letter-spacing: 3px;
  font-family: Montserrat;
  line-height: 1.9em;
  margin-bottom: 48px !important;
  text-align: justify;
}

@media only screen and (max-width:  768px) {
  .about-p-text{
    font-size: 18px !important;
    letter-spacing: 1.4px;
    font-family: Montserrat;
    line-height: 1.5em;
    margin-bottom: 48px !important;
    text-align: justify;
  }
}
.food-type{
  width: 25px;
  height: 25px;
}
.food-name{
  display: flex;
  justify-content: center;
  align-content: space-between;
}

.foodlist-fname{
  color: black;
}

.fc-list-banner{
  background-color: #e40000;
  border-radius: 5px;
  margin-top: 70px;
}

.food-header-text{
  font-size: 24.19px !important;
  font-family: Montserrat;
  /* text-transform: uppercase; */
  letter-spacing: 2.9px;
  color: #ffff;
  margin-bottom: 25px !important;
} 

.fclist-row{
  margin-top: 20px;
}
/*-----*/
.foodItems-fclist{
  height: 450px;
  width: 380px;
  display: inline-block;
  outline: none;
}


.fcategory-list-food-name{
  display: flex;
  justify-content: center;
}

.fcategory-list-fname{
  color: #666;
  font-family: Helvetica;
}


@media only screen and (max-width:  768px) {

  .foodItems-fclist{
    height: 450px;
    width:380px;
    display: inline-block;
    outline: none;
  }

}


@media only screen and (max-width:  380px) {

  .foodItems-fclist{
    height: 430px;
    width:330px;
    display: inline-block;
    outline: none;
  }

}
@media only screen and (max-width:  330px) {

  .foodItems-fclist{
    height: 430px;
    width:300px;
    display: inline-block;
    outline: none;
    padding-right: 12px;
  }

}






.rest-banner{
  background-color: #e40000;
  border-radius: 5px;
}



.rest-time-h4{
  text-align: right;
  padding-top: 12px;
  color: #ffff;
  font-size: 18px !important;
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100%;
}
.col-child-element {
  font-size: 24.19px !important;
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  color: #ffff;
  width: 100%;
  margin-bottom: 29px !important;
}
.rest-address{
  font-size: 18px;
  text-transform: capitalize;
}
.rest-heading{
  text-align: center;
}

.restaurant-close{
  width: 150px;
  height: 150px;
  position: absolute;
    width: 150px;
    height: 150px;
    z-index: -4;
    margin-top: -30px;
}
.r-closed{
  color: rgb(238, 226, 226);
  width: 140px;
  background-color: #ff3300;
}

@media only screen and (max-width:  768px) {

  .rest-time-h4{
    text-align: right;
    padding-top: 1px;
    color: #ffff;
    font-size: 16px !important;
    font-family: Montserrat;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .col-child-element {
    font-size: 20.19px !important;
    font-family: Montserrat;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ffff;
    width: 100%;
    margin-bottom: 13px !important;
  }
  .rest-address{
    font-size: 15px;
    text-transform: capitalize;
  }
  .rest-banner{
    background-color: #e40000;
    border-radius: 5px;
    margin-left: 0px;
    margin-right: 0px;
  }

}

.footer{
    bottom: 0;
    Padding-top:5px;
    background-color:#ff6a00;
}
.whole-footer{
    padding: "20px";
    position: "fixed";
    left: "0";
    bottom: "0";
    height: "60px";
    width: "100%";
}
.iconsocial{

    color:#ffffff;
    font-size:35px
}
.food1{
    Padding-top:35px;
    bottom: 0;
    
    background-color:#000000;
    text-align: center;
    align-items: center;
}

.footer-link{
    color: white;
}
.footer-nav{
    text-align: center;
    display: flex; 
    justify-content: space-around;
}
.footer1{
    padding-top: 10px;
}

.footer-nav li > a {
    color: white;
  }
.footer-nav li a:hover {
    background-color: #ff6a00;
    color: green;
}

.billing input{

    width:100%;
    height:40px;
    border-radius:10px;
    outline:none;
    background-color:rgb(202, 202, 202)    ;
    border-top:none;
    border-left:none;
    padding-left:20px;
    color:#065313;
}
.billing select{
    width:100%;
    height:40px;
    border-radius:10px;
    outline:none;
    background-color:rgb(202, 202, 202)   ;
  border:none;
    padding-left:20px;
    color:#065313;

}
h4, h3{
    font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
}
.priceWidth{
    width: 400px;
}
.priceWidthTotal{
    width: 800px;
}
.radiobutton input{

    width:50px;
    height:20px;
  
    color:green;
    background-color: green;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.otpinput{

width:200px;
border-radius:10px;
border:none;
outline:none;
background:#E1E6DF  ;
text-align:center;
height:30px;
font-size: 20px;
}

.otpinput::-webkit-input-placeholder {
    font-size: 10px;
    line-height: 3;
}

