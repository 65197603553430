
.nav-overlay-content {
    top: 64px;
    position: fixed; 
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    z-index: -1000;
    width: 100%;
    transition: width 10s ease-in;
    overflow-y: scroll;
    max-height: 90%;
}

.nav-hide {
    display: none
}


.nav-overlay-background {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 220;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    text-align:center;
    overflow-y: scroll;
    max-height: 90%;

}
.headings{
margin-bottom: 10px;
font-family: Montserrat;
text-transform: uppercase;
letter-spacing: 2.3px;
 
}
h4{
    color:rgb(102, 98, 98);
}
.navdrop{
    background-color:#F5F0EE ;
    padding:20px;
    border-radius:10px;
}
a{
    color:black;
}
.dispalaytogether{
    display:flex;
}

.category-list{
 margin-bottom: 10px;
 text-align: left;
}

.foodCatRest{
    font-family: 'Montserrat';
    font-weight: normal;
    font-style: normal;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
}

@media only screen and (max-width:  768px) {

    .foodCatRest{
        font-family: 'Montserrat';
        font-weight: normal;
        font-style: normal;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.1px;
    }

}

@media only screen and (max-width:  320px) {

    .foodCatRest{
        font-family: 'Montserrat';
        font-weight: normal;
        font-style: normal;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1px;
    }

}

