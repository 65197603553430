



.rest-banner{
  background-color: #e40000;
  border-radius: 5px;
}



.rest-time-h4{
  text-align: right;
  padding-top: 12px;
  color: #ffff;
  font-size: 18px !important;
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100%;
}
.col-child-element {
  font-size: 24.19px !important;
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  color: #ffff;
  width: 100%;
  margin-bottom: 29px !important;
}
.rest-address{
  font-size: 18px;
  text-transform: capitalize;
}
.rest-heading{
  text-align: center;
}

.restaurant-close{
  width: 150px;
  height: 150px;
  position: absolute;
    width: 150px;
    height: 150px;
    z-index: -4;
    margin-top: -30px;
}
.r-closed{
  color: rgb(238, 226, 226);
  width: 140px;
  background-color: #ff3300;
}

@media only screen and (max-width:  768px) {

  .rest-time-h4{
    text-align: right;
    padding-top: 1px;
    color: #ffff;
    font-size: 16px !important;
    font-family: Montserrat;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .col-child-element {
    font-size: 20.19px !important;
    font-family: Montserrat;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ffff;
    width: 100%;
    margin-bottom: 13px !important;
  }
  .rest-address{
    font-size: 15px;
    text-transform: capitalize;
  }
  .rest-banner{
    background-color: #e40000;
    border-radius: 5px;
    margin-left: 0px;
    margin-right: 0px;
  }

}
