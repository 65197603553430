.header-image {
  width: 100%;
  margin-top: -2em;
  margin-bottom: 2em;
}

.about-profile-pic {
  float: left;
  margin: 0 1em 1em 0;
}

.about-root-div{
  margin-top: 70px;
  padding-bottom: 20px;
}

.about-h1{
  font-size: 24.19px !important;
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  color: #464646;
  margin-bottom: 34px !important;
}
.about-p-text{
  font-size: 19px !important;
  letter-spacing: 3px;
  font-family: Montserrat;
  line-height: 1.9em;
  margin-bottom: 48px !important;
  text-align: justify;
}

@media only screen and (max-width:  768px) {
  .about-p-text{
    font-size: 18px !important;
    letter-spacing: 1.4px;
    font-family: Montserrat;
    line-height: 1.5em;
    margin-bottom: 48px !important;
    text-align: justify;
  }
}