

.show-grid{

  margin:0px;
}

  

  h3 {
    margin-bottom: 1em;
  }
  
  .home-carousal{
   background: black;
  }
  
 
  /*
  .pickofday-img:hover {
    border:10px solid #fff;
    box-shadow:0px 0px 5px #aaa;
  }*/
  
  .potd-head-underline {
    padding-bottom: 22px;
    color: black;
    border-bottom: rgb(236, 101, 11) 2px solid;
  }
 
 
