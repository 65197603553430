

.item {
    width: 100%;
  
}

.item img {
    width: 100%;
    height: 60%;
  
}
