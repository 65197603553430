body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #2892d7;
}

p {
  color: grey;
  font-weight: 300;
}