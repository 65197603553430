
/*
.profile-pic {
  width: 100%;
  transform: scale(1.0);
  transition: all 0.5s;
}*/
/*
.btn-primary {
  background-image: none;
  border: none;
  border-radius: 0;
  background-color: #2892D7;
}*/

h3 {
  margin-bottom: 1em;
}

.home-carousal{
 background: black;
}


.underline {
 
  color: #666;
  /*border-bottom: rgb(236, 101, 11) 2px solid;*/
  font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 3.8px;
}

.home-banner-phon-time{
  background-color: #e40000;
  border-radius: 6px;
  margin-top: 1px;
  padding-top: 3px;
  padding: 12px;
  letter-spacing: 2.3px;

  
}

.home-banner-text{
  text-align: center;
  font-size: 20px !important;
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  color: #ffff;
  line-height: 5px;
}

.quant-inc-drc {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 35px;
}
.homeLineBreak{
  visibility: hidden;
}
.phone-text1{
  color: white;
  font-size: 20px;
}
.phone-text2{
  color: white;
  font-size: 20px;
}


@media only screen and (max-width:  768px) {
  .home-banner-phon-time{
    background-color: #e40000;
    border-radius: 6px;
    padding: 0px;
    letter-spacing: 0.09px;
    
  }
  .home-banner-phone-time{
    display: flex;
    flex-flow: row wrap; 
    justify-content: space-around;
  }
  .phone-text1{
    color: white;
    font-size: 17px;
  }
  .phone-text2{
    color: white;
    font-size: 17px;
  }
  
 
.home-banner-text{
  text-align: center;
  font-size: 17px !important;
  font-family: Montserrat;
  text-transform: uppercase;
  letter-spacing: 2.3px;
  color: #ffff;
  line-height: 5px;
}

}
