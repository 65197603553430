body {
    font-family: Helvetica, sans-serif;
  }
  
  .react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 12px;
    border: 1px solid #f70d0d;;
    background-color: #f2f2f3;
   
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 40px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 12px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  .searchgrid{
    width:40%;
  }
  @media screen and (max-width: 900px) {
    .searchgrid {
      width:100%;
      margin-left:10px;
    }

    
  }
  .selectoption{

   
      height: 40px;
      border-top-left-radius: 20px;
     
      width: 100%;
      color:white;
      padding-left:30px;
      background-color: rgb(232, 99, 46);
      outline:none;
      border: 1px solid rgb(230, 119, 85);
 
  }
  @media screen and (max-width: 900px) {
    .selectoption{

   
      height: 40px;
      border-top-left-radius: 20px;
     
      width: 100%;
      color:white;
      padding-left:5px;
      font-size: 10px;
      background-color: rgb(232, 99, 46);
      outline:none;
      border: 1px solid rgb(230, 119, 85);
 
  }
    
  }