nav.navbar, .navbar-default {
  background-color: white !important;
  box-shadow: 0px 3px 9px rgba(133, 127, 127, 0.49); 
}
.navbar-default {
  background-image: none;
  border: none;
  border-radius: 0;
  height: 61px;
}
.navbar-default .navbar-nav > li > a {
  color: rgb(51, 160, 18);
  font-size: 14px;
  padding-top: 20px;
}
.navbar-default .navbar-brand {
  color: white;
}
.navbar-default .navbar-toggle {
  border-color: rgb(52, 143, 10);
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: rgb(38, 180, 20);
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover {
  color: white;
  background-color: transparent;
}
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: rgb(13, 118, 204);
  background-color: transparent;
}
.toi-logo{
  width: 200px;
  height: 35px;
}
.toi-cart{
  width: 40px;
  height: 40px;
}
.pop-menu-category {
  vertical-align: text-bottom;
}



/*common classes*/
.foodcat-trow{
  margin-top: 50px;
}
.fcat-food-type-menu{
  width: 18px;
  height: 18px;
  margin-bottom: 3px;
}
.num-input{
  width: 100px;
}

.food-price{
  color: black;
}

.f-img {
  width: 100%;
  border-radius: 7px;
  margin-top: 5px;
}

.col-wrapper {
  margin-bottom: 2em;
  outline: none;
  margin-left: 5px;
  margin-right: 5px;
}
.col-wrapper:hover .f-img {

  transform: translate(0, -5px);
  transition: all 0.5s;
}

.col-cart{
  margin-bottom: 4em;
  outline: none;
}

.col-cart:hover .f-img {

  transform: translate(0, -5px);
  transition: all 0.5s;
}

.fcategory-list-addsection{
  display: flex;
  justify-content: center;
  margin-top: 19px;
}
.fname{
    
  color: #666;
  text-transform: capitalize;
  font-size: 17px;
  font-family: 'Montserrat';
}
.fnameOldPrice{
    
  color: #666;
  text-transform: capitalize;
  font-size: 19px;
  font-family: 'Montserrat';
}
.fnamePrice{
  color: #666;
  text-transform: capitalize;
  font-size: 20px;
  margin-left: 18px;
  font-family: 'Montserrat';
}
.foodlist-type-name{
  display: flex;
  justify-content: center;
}

.fcategory-list-food-type{
  width: 18px;
  height: 18px;
  margin-top: 10px
}

.homepickday-food-name {
  display: flex;
  justify-content: center;
  position: relative;
}
.top-left {
  position: absolute;
  top: -30px;
  left: -2px;
  z-index: 2;
  color: white;
  width: 90px;
  background-color: green;
}
.top-left-tagline {
  position: absolute;
  top: -30px;
  left: -2px;
  z-index: 2;
  color: white;
  width: 90px;
  background-color: #ff6a00;
}

.homepickday-addsection{
  display: flex;
  justify-content: center;
  margin-top: 19px;
}

.pickofday-food-type{
  width: 18px;
  height: 18px;
  
  margin-top: 10px;
}

.table-wide {
  width: 500px;
}


.thead-row{
  max-width: 500px;
  
}
.btn-success{
  font-family: Montserrat;
  
  
}

.cart-model-navbar-mobile{
  visibility: hidden;
}



@media only screen and (max-width:  768px) {
  .col-home-rest-list{
    padding: 7px;
  }
  .slick-next {
   right: 0px;
   visibility: hidden;
  }
 
  .slick-prev {
    left: 0px;
    visibility: hidden;
}
  .col-wrapper  {
    margin-bottom: 2em;
    outline: none;
    margin-left: 3px;
    margin-right: 3px;
  }
  .toogle-search{
    margin-left: 20px;
    margin-top: -11px;
  }

  .toogle-box{
    margin-top: -9px;
    margin-left: 10px;
  }
  .nav-collapse-in-mobile{
    background-color: white;
    margin-top: 0px;
    padding-left: 0px;
    margin-top: -70px;
    
  }
  .cart-model-navbar {
    visibility: hidden;
  }

  .cart-model-navbar-mobile{
    margin-top: -9px;
    padding-left: 10px;
    visibility: visible;
    outline: none;
  }
 
  .login-singup-mobile-view{
    padding-left: 14px;
    margin-top: -3px;
  }
  .toi-logo{
    width: 115px;
    height: 33px;
    margin-left: -15px;
  }

  .col-wrapper:hover .f-img {

    transform: translate(0, 0px);
    transition: all 0s;
  }
}
