.ex4 .p1[data-count]:after{
    position: absolute;
    right: 2%;
    top: -3%;
    content: attr(data-count);
    font-size: 49%;
    padding: .3em;
    border-radius: 50%;
    line-height: 1em;
    color: white;
    background: rgba(255,0,0,.85);
    text-align: center;
    min-width: 1.5em;
  }
.p1 {
    color: rgb(236, 101, 11);
}
.ex4 {
    height: 10px;
    padding-bottom: 40px;
}
.tTotalQuant{
    height: 50px; 
    width: 50px;
    text-align: left;
    vertical-align: middle;
}
.tdTotal 
{
    height: 50px; 
    width: 50px;
    text-align: left;
    vertical-align: middle;
}
.tdTotalPrice 
{
    height: 50px; 
    width: 1900px;
    text-align: left;
    vertical-align: middle;
}
.tdTotalFoodName
{
    height: 50px; 
    width: 800px;
    text-align: left;
    vertical-align: middle;
}

.cart-empty-div{
    display: flex; 
    justify-content: center;
}
.emptyCart{
    height: 300px;
    width: 350px;
}
.addonDiv{
    display: flex;
    flex-direction: row;
    align-items: center;

}